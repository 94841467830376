import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs';
import { User } from './user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    // readonly rootUrl = 'http://192.168.0.32:3018/api';
    // readonly rootUrl = 'https://skincare.orbo.ai/api';
    // readonly rootUrl = 'https://skincare2.orbo.ai/api';
    // readonly rootUrl = 'https://dev.skinanalysis.orbo.ai/api';
    // readonly rootUrl = window.location.protocol + '//' + window.location.host + '/api';
    readonly rootUrl = 'https://orbo.azure-api.net/skinanalysis/v4-2';
    // readonly accessKey = 'DEV083C3CAC6CC4184A8FEA03F80A45D01ORBO'; // ORBO key
    // readonly clientKey = 'wQwHoTU4P6COREUI';

    readonly clientKey = 'SANDBOX'
    // readonly accessKey = 'bca030f0e7b94da1a4b019a9ebbcd872'

    user: User;

    constructor(private _http: HttpClient) { }

    registerUser(user: User) {
        const body: User = {
            userName: user.userName,
            email: user.email,
            password: user.password,
        };
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post(this.rootUrl + '/members', body, { headers: reqHeader });
    }

    userAuthentication(email, password) {
        const data = {
            email: email,
            password: password
        };
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._http.post(this.rootUrl + '/members/login', data, { headers: reqHeader });
    }

    analyzeSkin(image, skinType, age, access_token) {
        const form = new FormData();
        form.append('src_image', image);
        // form.append('skinType', skinType);
        // form.append('age', age);
        const options = {
            reportProgress: true,
        };
        return this._http.post(`https://orbo.azure-api.net/blrm/v1/bald?access_token=${access_token}`, form, options);
    }

    analyzeBeard(image, code, beard_code, access_token) {
        const form = new FormData();
        form.append('src_image', image);
        form.append('code', code);
        form.append('beard_code', beard_code);
        const options = {
            reportProgress: true,
        };
        return this._http.post(`https://orbo.azure-api.net/btrfrm/v2/transform?access_token=${access_token}`, form, options);
    }

    logout(access_token) {
        return this._http.post(this.rootUrl + '/members/logout?access_token=' + access_token, null);
    }
}
